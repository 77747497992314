import { Link } from "gatsby";
import * as React from "react";
import Cookies from "universal-cookie";
import "../assets/css/404.scss";
import not_found_img from "../assets/images/404.svg";
import SEO from "../components/layout/seo";
import languages from "../utils/enums/languages";

const cookies = new Cookies();

const NotFoundPage = () => {
    let language = cookies.get('currentLang');
    if (!language || !languages.includes(language)) {
      language = 'en';
    }
    
    return (
    <>
      <SEO title="404: Not found" />
      <div className="notfound-page">
        <div>
          <img className="d-block mx-auto img-404" src={not_found_img} alt="404: Not Found" />
          <h1 className="mt-5 text-center">Error</h1>
          <p className="mt-3 mb-5 text-center">Looks like something went wrong on our end. Page not available</p>
          <div className="d-flex justify-content-center">
            <Link to={`/`}>
              <button
              className="btndefault greenbtn"
              >
                Go to homepage
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotFoundPage
